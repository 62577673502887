angular.module('jb.filters').filter('stripHtml', ['RegexConstants',
  function(RegexConstants) {
    return function(text) {
      let withoutNonbreakingSpaces = text.replace(RegexConstants.NonbreakingSpace, ' ');
      let withoutNewlines = withoutNonbreakingSpaces.replace(RegexConstants.NewlineRegex, ' ');
      let withoutStyling = withoutNewlines.replace(RegexConstants.EmbeddedStylingRegex, '');
      let withoutHtml = withoutStyling.replace(RegexConstants.HtmlMatcherRegex, '');
      let trimmedWhitespace = withoutHtml.replace(RegexConstants.BoundaryWhitespaceRegex, '');
      return trimmedWhitespace;
    };
  }
]);
