angular.module('jb').run(['JobBoardConfig', '$window', '_',
  function(JobBoardConfig, $window, _) {
    if (angular.isDefined($window.jasmine)) {
      return;
    }

    let alreadyLinked = (tagName, attrName, value) => {
      let matches = document.querySelectorAll(tagName);
      for (var i in matches) {
        if (matches[i][attrName] === value) {
          return true;
        }
      }
      return false;
    };

    let loadScript = (src, options) => {
      if (alreadyLinked('script', 'src', src)) {
        return;
      }
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      _.each(options || {}, (value, key) => script.setAttribute(key, value));
      document.body.appendChild(script);
    };

    let loadStylesheet = (href) => {
      if (alreadyLinked('link', 'href', href)) {
        return;
      }
      let appStylesheet = document.createElement('link');
      appStylesheet.type = 'text/css';
      appStylesheet.rel = 'stylesheet';
      appStylesheet.href = href;
      document.head.appendChild(appStylesheet);
    };

    let dropBoxOptions = {
      id: 'dropboxjs',
      'data-app-key': 'bejr3g0u3im1w9e'
    };
    loadScript('https://www.dropbox.com/static/api/2/dropins.js', dropBoxOptions);
    loadScript('https://apis.google.com/js/client.js');
    loadScript('https://apis.google.com/js/api.js');
    if (!$window._babelPolyfill) {
      loadScript('https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.js');
    }

    loadStylesheet(JobBoardConfig.domain + '/app.css');
  }
]);
