angular.module('jb.factories').factory('PathToResourceRoute', function() {
  return {
    convert: function(path) {
      if (angular.isUndefined(path)) {
        throw 'Provided path does not exist';
      }
      var route = path.toString().replace('(.:format)', '').replace(/\/\//g, '/');
      var toCamel = function(str) {
        return str.replace(/([-_][a-z])/g, function($1) {
          return $1.toUpperCase().replace(/[-_]/, '');
        });
      };

      var findColonVariables = /:([a-z_]+)/g;
      route = route.replace(findColonVariables, function(match) {
        match = match.substring(1);
        match = toCamel(match);
        return '{{' + match + '}}';
      });
      return route;
    }
  };
});
