angular.module('jb.components').component('jbMessagingTermsAndConditionsModal', {
  bindings: {
    close: '<'
  },
  templateUrl: 'messaging_terms_and_conditions/modal.component.html',
  controller: ['JobBoardService',
    function(JobBoardService) {
      this.$onInit = () => {
        this.termsAndConditions = JobBoardService.getTermsAndConditions();
        this.ctaPrivacyPolicyUrl = JobBoardService.getCtaPrivacyPolicyUrl();

        this.checkTermsAndConditions(this.termsAndConditions);
      };

      this.checkTermsAndConditions = (termsAndConditions) => {
        const match = /(.*)(Privacy Policy)(.*)/i.exec(termsAndConditions);

        if (match) {
          this.matchedPrivacyPolicy = true;
          this.prefixTermsAndConditions = match[1];
          this.privacyPolicyText = match[2];
          this.postfixTermsAndConditions = match[3];
        } else {
          this.matchedPrivacyPolicy = false;
        }
      };

      this.cancel = () => {
        this.close();
      };
    }
  ]
});
