angular.module('jb.directives').directive('jbAutocomplete', [function() {
  return {
    restrict: 'E',
    require: 'ngModel',
    controller: 'AutocompleteDirectiveController',
    scope: {
      ngModel: '=',
      change: '=',
      maxlength: '@',
      ngStyle: '=',
      ngRequired: '=',
      submitOnEnter: '@',
      noResultsText: '@'
    },
    templateUrl: 'directives/autocomplete.html'
  };
}]);
