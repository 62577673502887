angular.module('jb.factories').factory('JobSearchFactory', ['railsResourceFactory', 'PathToResourceRoute',
  function(railsResourceFactory, PathToResourceRoute) {
    let JobSearchFactory = railsResourceFactory({
      url: PathToResourceRoute.convert(JBRoutes.jobBoardJobSearchesPath),
      name: 'jobSearch'
    });

    JobSearchFactory.oneOffSearch = (searchParams) => {
      let path = JBRoutes.jobBoardOneOffSearchJobSearchesPath();

      return JobSearchFactory.$get(path, searchParams);
    };

    JobSearchFactory.quicklinkSearch = (searchParams) => {
      let path = JBRoutes.jobBoardQuicklinkSearchJobSearchesPath();
      return JobSearchFactory.$get(path, searchParams);
    };

    return JobSearchFactory;
  }
]);
