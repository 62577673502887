angular.module('jb.factories').factory('JobFactory', ['railsResourceFactory', 'PathToResourceRoute',
  function(railsResourceFactory, PathToResourceRoute) {
    let JobFactory = railsResourceFactory({
      url: PathToResourceRoute.convert(JBRoutes.jobBoardJobPostingsPath),
      name: 'job'
    });

    return JobFactory;
  }
]);
