angular.module('jb.interceptors').factory('JobBoardErrorInterceptor', ['$injector', '$q',
  function($injector, $q) {
    return {
      responseError: function(response) {
        switch (response.status) {
          case 401:
            $injector.get('$state').go('root.inactive');
            break;
          case 403:
            $injector.get('$state').go('root.unavailable');
            break;
          case 500:
          case 502:
          case 503:
            $injector.get('$state').go('root.error');
            break;
        }
        return $q.reject(response);
      }
    };
  }
]);
