angular.module('jb.factories').factory('CacheableResourceFactory', ['$q', '_', 'SingleInstanceRequestFactory',
  function($q, _, SingleInstanceRequestFactory) {
    const DEFAULT_CACHE_KEY = 'v1';

    class CacheableResourceFactory {
      constructor(getServerValue, options = {}) {
        this.getServerValue = getServerValue;
        this.getValueFromResponse = options.getValueFromResponse || _.identity;
        this.refreshFromServerRequest = new SingleInstanceRequestFactory(this.updateFromServer.bind(this));
      }

      setCachedValue(newValue) {
        this.cachedValue = newValue;
      }

      updateCurrentCacheKey() {
        this.currentCacheKey = this.lastRequestedCacheKey;
      }

      _cachedValueOutdated() {
        return this.currentCacheKey !== this.lastRequestedCacheKey;
      }

      getCachedValue() {
        if (this._cachedValueOutdated()) {
          return;
        }

        return this.cachedValue;
      }

      clearCachedValue() {
        this.setCachedValue(null);
      }

      clearCurrentCacheKey() {
        this.currentCacheKey = null;
      }

      updateFromServer() {
        let onSuccess = (response) => {
          let value = this.getValueFromResponse(response);
          this.setCachedValue(value);
          this.updateCurrentCacheKey();
          return value;
        };
        let onFailure = (response) => {
          this.clearCachedValue();
          this.clearCurrentCacheKey();
          return $q.reject(response);
        };

        return this.getServerValue().then(onSuccess, onFailure);
      }

      get(requestedCacheKey = DEFAULT_CACHE_KEY) {
        this.lastRequestedCacheKey = requestedCacheKey;
        let cachedValue = this.getCachedValue();
        if (cachedValue) {
          return $q.when(cachedValue);
        }

        return this.refreshFromServerRequest.getOrCreate();
      }
    }

    return CacheableResourceFactory;
  }
]);
