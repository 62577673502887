angular.module('jb.filters').filter('payRange', ['$filter', '_', function($filter, _) {
  let formatAmount = (amount) => {
    if (!amount) {
      return undefined;
    }

    let isWholeNumber = parseFloat(amount) % 1 === 0;
    if (isWholeNumber) {
      return $filter('currency')(amount, '$', 0);
    }

    return $filter('currency')(amount);
  };

  return function(minimum, maximum) {
    let minFormat = formatAmount(minimum);
    let maxFormat = formatAmount(maximum);

    let amounts = _.compact([minFormat, maxFormat]);
    return amounts.join(' - ');
  };
}]);
