angular.module('jb.controllers.directives').controller('AutocompleteDirectiveController', ['$scope', '$attrs',
  function($scope, $attrs) {
    var types = {
      'location': {
        path: JBRoutes.jobBoardAutoCompleteLocationsPath() + '?term=',
        name: 'formatted',
        titleField: 'formatted'
      }
    };
    var selected;
    $scope.type = types[$attrs.type];
    $scope.placeholder = $attrs.placeholder;
    $scope.inputStr = $scope.ngModel;

    if (angular.isUndefined($scope.submitOnEnter)) { $scope.submitOnEnter = false; }
    if (angular.isUndefined($scope.noResultsText)) { $scope.noResultsText = 'No Results Found'; }

    $scope.delegateName = $attrs.delegateName;
    $scope.isRequiredField = function() {
      var required = $scope.ngRequired || $attrs.hasOwnProperty('fieldRequired');
      return required ? true : false;
    };

    $scope.getDisplayValue = function() {
      if (!$scope.ngModel) {
        return;
      }

      return $scope.type.display ? $scope.ngModel[$scope.type.display] : $scope.ngModel;
    };

    $scope.getIdName = function() {
      return $scope.type ? 'angularcomplete-' + $scope.type.name : 'angucomplete';
    };

    $scope.getDelegates = function() {
      if (!$scope.autoCompleteDelegates) {
        $scope.autoCompleteDelegates = {};
      }

      return $scope.autoCompleteDelegates;
    };

    $scope.$watch(
      function($scope) {
        return $scope.getDisplayValue();
      },
      function(newValue) {
        $scope.getDelegates()[$scope.delegateName] = newValue;
        $scope.$broadcast('angucomplete-alt:changeInput', $scope.getIdName(), newValue);
      }
    );

    $scope.selectedItem = function(item) {
      selected = item;
      $scope[$attrs.type + 'Handler']();
    };

    // Selection Handlers

    $scope.locationHandler = function() {
      if (!selected) {
        $scope.ngModel = null;
      } else if (selected.originalObject && !angular.isString(selected.originalObject)) {
        $scope.ngModel = selected.originalObject.formatted;
      } else {
        $scope.ngModel = selected.originalObject;
      }
    };

    $scope.$watch('ngModel', function() {
      if ($scope.change) {
        $scope.change();
      }
    });

    $scope.changed = function(inputStr) {
      $scope.inputStr = inputStr;

      return inputStr;
    };
  }
]);
