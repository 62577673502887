angular.module('jb').run(['$rootScope', '$window', 'resize', 'ViewStateService',
  function($rootScope, $window, resize, ViewStateService) {
    let applyScopeOnResize = () => {
      resize.bind();
    };
    applyScopeOnResize();

    let scrollToTopOfPage = () => { $window.scrollTo(0, 0); };
    $rootScope.$on('$stateChangeSuccess', scrollToTopOfPage);

    $rootScope.isMobileView = ViewStateService.isMobileView;
  }
]);
