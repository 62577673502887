angular.module('jb').config(['$stateProvider', '$urlRouterProvider', '$sceDelegateProvider', '$httpProvider', '$qProvider', '$compileProvider', 'resizeProvider', 'StatesConstant', 'JobBoardConfig', 'ApiUrlConstant',
  function($stateProvider, $urlRouterProvider, $sceDelegateProvider, $httpProvider, $qProvider, $compileProvider, resizeProvider, StatesConstant, JobBoardConfig, ApiUrlConstant) {
    let configureApplyOnResize = () => {
      resizeProvider.throttle = 100;
      resizeProvider.initBind = false;
    };
    configureApplyOnResize();

    $sceDelegateProvider.resourceUrlWhitelist([
      'self', 'http://dev.topechelon.com/*', 'https://secure.topechelon.com/**'
    ]);

    $qProvider.errorOnUnhandledRejections(false);

    $httpProvider.defaults.headers.common['Accept'] = 'application/json';
    $httpProvider.defaults.headers.common['Content-Type'] = 'application/json';
    $httpProvider.defaults.headers.common['Authorization'] = `Apikey ${JobBoardConfig.apiKey}`;

    $httpProvider.interceptors.push('JobBoardErrorInterceptor');

    // Disable AngularJS debug information in production by default for performance
    // https://docs.angularjs.org/guide/production#disabling-debug-data
    let isProductionApi = ApiUrlConstant.includes('bb3api.topechelon.com');
    if (isProductionApi) {
      $compileProvider.debugInfoEnabled(false);
    }

    let loadStates = (states, parent) => {
      angular.forEach(states, (state, key) => {
        state.parent = parent;

        if (state.parent) {
          state.name = parent + '.' + key;
        } else {
          state.name = key;
        }

        $stateProvider.state(state.name, state);

        if (state.children) {
          loadStates(state.children, state.name);
        }
      });
    };
    loadStates(StatesConstant);

    $urlRouterProvider.otherwise('/search?page=1');
  }
]);
