angular.module('jb.services')
  .service('AbstractModalService', ['ModalService', '$q', function(ModalService) {
    this.show = function(modalParams) {
      let modalRef;
      let elements;

      let handleModalOpen = (modal) => {
        modalRef = modal;

        elements = document.querySelectorAll('.modal-bg');
        elements.forEach((element) => element.classList.toggle('ng-hide', element !== modal.element[0]));

        document.querySelector('html').style.overflow = 'hidden';
        modal.element[0].style.display = '';
        modal.element[0].tabIndex = 0;
        modal.element[0].focus();
        return modal.close;
      };

      let handleModalClose = (response) => {
        document.querySelector('html').style.overflow = 'initial';
        elements.forEach((element) => element.classList.toggle('ng-hide', element === modalRef.element[0]));
        return response;
      };

      return ModalService.showModal(modalParams).then(handleModalOpen).then(handleModalClose);
    };
  }]);
