var prefix = function() {
  return 'http://bb3api.localhost.bb3:3000';
};

var build_wrapper = function(original) {
  var path_fn;
  path_fn = function() {
    return prefix() + original.apply(this, arguments);
  };
  path_fn.required_params = original.required_params;
  path_fn.toString = function() {
    return prefix().replace(/\//g, '//') + original.toString();
  };
  return path_fn;
};
Object.keys(JBRoutes).forEach(function(routes) {
  JBRoutes[routes] = build_wrapper(JBRoutes[routes]);
});
