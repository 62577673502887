angular.module('jb.factories').factory('StateFactory', ['railsResourceFactory', 'PathToResourceRoute', 'CacheableResourceFactory',
  function(railsResourceFactory, PathToResourceRoute, CacheableResourceFactory) {
    var State = railsResourceFactory({
      url: PathToResourceRoute.convert(JBRoutes.jobBoardStatesPath),
      name: 'state'
    });

    var cachedStates = new CacheableResourceFactory(function() {
      return State.query();
    });

    State.lazyFetchAll = function() {
      return cachedStates.get();
    };

    State.fetchAllFromCountry = function(countryId) {
      return State.query({ countryId: countryId });
    };

    var cachedStatesByCountry = {};
    State.lazyFetchAllFromCountry = function(countryId) {
      if (!cachedStatesByCountry[countryId]) {
        cachedStatesByCountry[countryId] = new CacheableResourceFactory(function() {
          return State.fetchAllFromCountry(countryId);
        });
      }
      return cachedStatesByCountry[countryId].get();
    };

    return State;
  }
]);
