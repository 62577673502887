angular.module('jb.constants').constant('StatesConstant', {
  root: {
    abstract: true,
    url: '?venue&utm_source',
    template: '<ui-view></ui-view>',
    children: {
      search: {
        url: '/search?id&qlSearchId&nqlSearchId&page&params&filter',
        templateUrl: 'search.html',
        controller: 'JobSearchController',
        resolve: {
          pageNumber: ['$stateParams', ($stateParams) => {
            return parseInt($stateParams.page) || 1;
          }],
          searchParams: ['$stateParams', ($stateParams) => {
            return angular.fromJson($stateParams.params) || {};
          }],
          filter: ['$stateParams', ($stateParams) => {
            return $stateParams.filter;
          }],
          searchResult: ['pageNumber', 'searchParams', '$stateParams', 'JobSearchFactory', (pageNumber, searchParams, $stateParams, JobSearchFactory) => {
            let params = angular.copy(searchParams);
            params.quicklinkSearchId = $stateParams.qlSearchId;
            params.networkQuicklinkSearchId = $stateParams.nqlSearchId;
            params.page = pageNumber;
            params.filter = $stateParams.filter;

            if ($stateParams.id) {
              return JobSearchFactory.get($stateParams.id, { page: pageNumber });
            } else if (angular.isDefined(params.quicklinkSearchId) || angular.isDefined(params.networkQuicklinkSearchId)) {
              return JobSearchFactory.quicklinkSearch(params);
            } else {
              return JobSearchFactory.oneOffSearch(params);
            }
          }]
        }
      },
      apply: {
        url: '/apply',
        templateUrl: 'apply.html',
        controller: 'ApplyController',
        resolve: {
          application: ['JobApplicationFactory', '$stateParams', (JobApplicationFactory, $stateParams) => {
            return new JobApplicationFactory({ venue: $stateParams.utm_source || $stateParams.venue });
          }]
        }
      },
      thanks: {
        url: '/thanks',
        templateUrl: 'thanks.html',
        controller: 'ThankYouController',
        resolve: {
          jobTitle: () => ''
        }
      },
      alerts: {
        abstract: true,
        url: '/alerts',
        template: '<ui-view></ui-view>',
        children: {
          alert: {
            abstract: true,
            url: '/:alertId',
            template: '<ui-view></ui-view>',
            resolve: {
              jobAlert: ['JobAlertFactory', '$stateParams', (JobAlertFactory, $stateParams) => {
                return JobAlertFactory.get($stateParams.alertId);
              }]
            },
            children: {
              cancel: {
                url: '/cancel',
                templateUrl: 'cancel.html',
                controller: 'JobAlertCancelController'
              },
              renew: {
                url: '/renew',
                templateUrl: 'renew.html',
                controller: 'JobAlertRenewController'
              }
            }
          }
        }
      },
      privacyComplianceRequests: {
        abstract: true,
        url: '/privacy_compliance_requests',
        template: '<ui-view></ui-view>',
        children: {
          privacyComplianceRequest: {
            abstract: true,
            url: '/:privacyComplianceRequestId',
            template: '<ui-view></ui-view>',
            resolve: {
              privacyComplianceRequest: ['PrivacyComplianceRequestFactory', '$stateParams', (PrivacyComplianceRequestFactory, $stateParams) => {
                return PrivacyComplianceRequestFactory.get($stateParams.privacyComplianceRequestId);
              }]
            },
            children: {
              confirm: {
                url: '/confirm',
                templateUrl: 'confirm.html',
                controller: 'PrivacyComplianceRequestConfirmController'
              }
            }
          }
        }
      },
      job: {
        url: '/:id',
        template: '<ui-view></ui-view>',
        abstract: true,
        resolve: {
          jobPosting: ['JobPostingFactory', '$stateParams', (JobPostingFactory, $stateParams) => {
            return JobPostingFactory.get($stateParams.id);
          }]
        },
        children: {
          detail: {
            url: '/detail',
            templateUrl: 'detail.html',
            controller: 'JobDetailController'
          },
          apply: {
            url: '/apply',
            templateUrl: 'apply.html',
            controller: 'ApplyController',
            resolve: {
              application: ['JobApplicationFactory', 'jobPosting', '$stateParams', (JobApplicationFactory, jobPosting, $stateParams) => {
                let params = {
                  jobId: jobPosting.job.id,
                  job: jobPosting.job,
                  venue: $stateParams.utm_source || $stateParams.venue
                };
                return new JobApplicationFactory(params);
              }]
            }
          },
          thanks: {
            url: '/thanks',
            templateUrl: 'thanks.html',
            controller: 'ThankYouController',
            resolve: {
              jobTitle: ['jobPosting', (jobPosting) => jobPosting.job.positionTitle]
            }
          }
        }
      },
      error: {
        url: '/error',
        templateUrl: 'error.html'
      },
      inactive: {
        url: '/inactive',
        templateUrl: 'inactive.html'
      },
      unavailable: {
        url: '/unavailable',
        templateUrl: 'unavailable.html'
      }
    }
  }
});
