angular.module('jb.controllers.directives')
  .controller('CountryPickerDirectiveController', ['$scope', 'CountryFactory', '_',
    function($scope, CountryFactory, _) {
      CountryFactory.lazyFetchAll().then(function(response) {
        $scope.countries = response;
        if (angular.isDefined($scope.countryIds)) {
          $scope.countries = _.select(response, country => $scope.countryIds.includes(country.id));
        }
      });
    }
  ]);
