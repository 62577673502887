angular.module('jb.components').component('jbPrivacyComplianceOptionsModal', {
  bindings: {
    close: '<'
  },
  templateUrl: 'privacy_compliance/options/modal.component.html',
  controller: ['JobBoardService', 'AbstractModalService', '$sce',
    function(JobBoardService, AbstractModalService, $sce) {
      this.$onInit = () => {
        this.agencyName = JobBoardService.getAgencyName();
        this.privacyComplianceEmail = JobBoardService.getPrivacyComplianceEmail();
        this.privacyComplianceContactInfo = $sce.trustAsHtml(JobBoardService.getPrivacyComplianceContactInfo());
        this.privacyComplianceUrl = JobBoardService.getPrivacyComplianceUrl();
        this.ccpaEnabled = JobBoardService.getCcpaEnabled();

        JobBoardService.getPrivacyComplianceText().then((text) => {
          this.privacyComplianceText = $sce.trustAsHtml(text);
        });
      };

      let showModalWithRequestType = (requestType) => {
        let modalOptions = {
          component: 'jbPrivacyComplianceConfirmationEmailAddressModal',
          bindings: {
            requestType: requestType
          }
        };

        AbstractModalService.show(modalOptions).then(this.close);
      };

      this.doNotSell = () => {
        showModalWithRequestType('do_not_sell');
      };

      this.requestData = () => {
        showModalWithRequestType('data');
      };

      this.forgetMyInfo = () => {
        showModalWithRequestType('delete_me');
      };

      this.cancel = () => {
        this.close();
      };
    }
  ]
});
