angular.module('jb.filters').filter('sce', ['$sce',
  function($sce) {
    // WARNING: use caution when using this filter. Improperly trusting a string
    // value could introduce XSS vulnerabilities. Only trust user-defined values
    // if they have been scrubbed by the api.
    return (value, fn) => {
      // Account for blank values or strings that have already been trusted.
      if (!angular.isString(value)) {
        return value;
      }

      return $sce[fn](value);
    };
  }
]);
