angular.module('jb.controllers').controller('JobAlertCancelController', ['$scope', 'jobAlert', 'JobBoardService',
  function($scope, jobAlert, JobBoardService) {
    $scope.jobAlert = jobAlert;
    $scope.jobBoardService = JobBoardService;

    $scope.cancelAll = false;
    $scope.canceling = false;
    $scope.canceled = false;

    let handleSuccess = () => {
      $scope.canceled = true;
    };
    let handleFailure = () => {
      $scope.errorMessage = 'There was an error processing your request.';
    };
    let finishCanceling = () => {
      $scope.canceling = false;
    };

    let cancelAlert = (all) => {
      $scope.canceling = true;

      jobAlert.cancel(all).then(handleSuccess, handleFailure).finally(finishCanceling);
    };

    if (jobAlert.alertsBelongingToSameEmailCount === 1) {
      cancelAlert();
    }

    $scope.cancel = () => {
      if ($scope.canceling) {
        return;
      }

      cancelAlert($scope.cancelAll);
    };
  }
]);
