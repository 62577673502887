angular.module('jb.filters').filter('titleize', function() {
  var lowercaseWords = ['and', 'or', 'nor', 'in', 'of', 'a', 'the', 'to'];

  var capitalize = function(string, position) {
    string = string.toLowerCase();
    if (lowercaseWords.indexOf(string) >= 0 && position !== 0) {
      return string.toLowerCase();
    }

    var words = string.split('-');
    var capitalized = words.map(function(word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return capitalized.join('-');
  };

  return function(input) {
    if (!input) {
      return '';
    }

    let string;

    if (input instanceof Array) {
      string = input.join(', ');
    } else {
      string = input.toString();
    }

    var array = [];
    var words = string.split(/[ _]+/);
    words.forEach(function(word, index) {
      array.push(capitalize(word, index));
    });

    return array.join(' ');
  };
});
