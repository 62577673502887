angular.module('jb.factories').factory('JobApplicationFactory', ['railsResourceFactory', 'PathToResourceRoute',
  function(railsResourceFactory, PathToResourceRoute) {
    let JobApplicationFactory = railsResourceFactory({
      url: PathToResourceRoute.convert(JBRoutes.bigBillerJobBoardJobApplicationsPath),
      name: 'job_application'
    });

    return JobApplicationFactory;
  }
]);
