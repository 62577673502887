angular.module('jb.components').component('jbPrivacyComplianceOptionsLink', {
  templateUrl: 'privacy_compliance/options/link.component.html',
  controller: ['AbstractModalService',
    function(AbstractModalService) {
      this.openModal = () => {
        let modalOptions = {
          component: 'jbPrivacyComplianceOptionsModal',
          bindings: {}
        };

        AbstractModalService.show(modalOptions);
      };
    }
  ]
});
