angular.module('jb.factories').factory('CountryFactory', ['railsResourceFactory', 'PathToResourceRoute', 'CacheableResourceFactory',
  function(railsResourceFactory, PathToResourceRoute, CacheableResourceFactory) {
    var Country = railsResourceFactory({
      url: PathToResourceRoute.convert(JBRoutes.jobBoardCountriesPath),
      name: 'country'
    });

    var cachedCountries = new CacheableResourceFactory(function() {
      return Country.query();
    });

    Country.lazyFetchAll = function() {
      return cachedCountries.get();
    };

    return Country;
  }
]);
