angular.module('jb.components').component('jbMessagingTermsAndConditionsLink', {
  templateUrl: 'messaging_terms_and_conditions/link.component.html',
  controller: ['AbstractModalService',
    function(AbstractModalService) {
      this.openModal = () => {
        let modalOptions = {
          component: 'jbMessagingTermsAndConditionsModal',
          bindings: {}
        };

        AbstractModalService.show(modalOptions);
      };
    }
  ]
});
