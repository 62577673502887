angular.module('jb.services').service('JobBoardService', ['$http', 'BoardSettingsFactory', '$q', 'rxjs',
  function($http, BoardSettingsFactory, $q, rxjs) {
    this.shouldDisplayJobId = () => this.board && this.board.settings.display_job_id;
    this.shouldDisplayJobType = () => this.board && this.board.settings.display_job_type;
    this.shouldDisplaySalary = () => this.board && this.board.settings.display_salary;
    this.allowCountrySearching = () => this.board && this.board.settings.allow_country_searching;
    this.allowRemoteSearching = () => this.board && this.board.settings.allow_remote_searching;

    this.board$ = new rxjs.Subject();

    this.getAgencyName = () => {
      if (!this.board) {
        return;
      }

      return this.board.agency_name;
    };

    this.hasVerifiedUrl = () => {
      if (!this.board) {
        return false;
      }

      return !!this.board.url;
    };

    this.getPrivacyComplianceEnabled = () => {
      if (!this.board) {
        return false;
      }

      return this.board.settings.privacy_compliance_enabled;
    };

    this.getPrivacyComplianceEmail = () => {
      if (!this.board) {
        return;
      }

      return this.board.settings.privacy_compliance_email;
    };

    this.getPrivacyComplianceText = () => {
      if (!this.board) {
        return;
      }

      let text = this.board.settings.privacy_compliance_text || this.defaultPrivacyComplianceText;
      if (text) {
        return $q.when(text);
      }

      return BoardSettingsFactory.getDefaultPrivacyComplianceText().then((response) => {
        this.defaultPrivacyComplianceText = response;
        return response;
      });
    };

    this.getPrivacyComplianceContactInfo = () => {
      if (!this.board) {
        return;
      }

      return this.board.settings.privacy_compliance_contact_info;
    };

    this.getPrivacyComplianceUrl = () => {
      if (!this.board) {
        return;
      }

      return this.board.settings.privacy_compliance_url;
    };

    this.getCtaPrivacyPolicyUrl = () => {
      if (!this.board) {
        return;
      }

      return this.board.settings.cta_privacy_policy_url;
    };

    this.getTermsAndConditions = () => {
      if (!this.board) {
        return;
      }

      return this.board.settings.terms_and_conditions;
    };

    this.getCcpaEnabled = () => {
      if (!this.board) {
        return false;
      }

      return this.board.settings.ccpa_enabled;
    };

    this.getGdprEnabled = () => {
      if (!this.board) {
        return false;
      }

      return this.board.settings.gdpr_enabled;
    };

    this.init = () => {
      let url = JBRoutes.jobBoardPath();

      let handleResponse = (response) => {
        this.board = response.data;
        this.board$.next();
      };

      $http.get(url).then(handleResponse);
    };

    this.getBoard$ = () => {
      return this.board$;
    };
  }
]);
