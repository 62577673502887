angular.module('jb.controllers').controller('PrivacyComplianceRequestConfirmController', ['$scope', 'privacyComplianceRequest', 'JobBoardService',
  function($scope, privacyComplianceRequest, JobBoardService) {
    $scope.privacyComplianceRequest = privacyComplianceRequest;
    $scope.message = 'Request confirmed.';
    $scope.privacyComplianceEmail = JobBoardService.getPrivacyComplianceEmail();

    JobBoardService.getBoard$().subscribe(() => {
      $scope.privacyComplianceEmail = JobBoardService.getPrivacyComplianceEmail();
    });

    $scope.confirming = false;
    $scope.confirmed = false;

    let handleSuccess = () => {
      $scope.confirmed = true;
    };
    let handleFailure = () => {
      $scope.errorMessage = 'There was an error processing your request.';
    };
    let finishConfirming = () => {
      $scope.confirming = false;
    };

    let initializeMessage = () => {
      if ($scope.privacyComplianceRequest.requestType === 'do_not_sell') {
        $scope.message = `We have set the record associated with the email address of ${privacyComplianceRequest.emailAddress} as "Do Not Sell My Personal Data."`;
      } else if ($scope.privacyComplianceRequest.requestType === 'data') {
        $scope.message = 'Thank you for confirming your "Request My Info" request.  We will be reaching out to you regarding your data.';
      } else if ($scope.privacyComplianceRequest.requestType === 'delete_me') {
        $scope.message = 'Thank you for confirming your "Forget My Info" request.  We will be reaching out to you regarding your data.';
      }
    };

    let confirmPrivacyComplianceRequest = () => {
      $scope.confirming = true;

      privacyComplianceRequest.confirm().then(handleSuccess, handleFailure).finally(finishConfirming);
    };

    initializeMessage();
    confirmPrivacyComplianceRequest();
  }
]);
