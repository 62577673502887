angular.module('jb.factories').factory('BoardSettingsFactory', ['railsResourceFactory', 'PathToResourceRoute',
  function(railsResourceFactory, PathToResourceRoute) {
    let BoardSettingsFactory = railsResourceFactory({
      url: PathToResourceRoute.convert(JBRoutes.jobBoardBoardSettingsPath),
      name: 'boardSettings'
    });

    BoardSettingsFactory.getDefaultPrivacyComplianceText = () => {
      let path = JBRoutes.jobBoardDefaultPrivacyComplianceTextBoardSettingsPath();
      return BoardSettingsFactory.$get(path);
    };

    return BoardSettingsFactory;
  }
]);
