angular.module('jb.constants').constant('PermittedResumeExtensionsConstant', [
  '.doc',
  '.docx',
  '.pdf',
  '.txt',
  '.rtf',
  '.html',
  '.png',
  '.jpg',
  '.gif',
  '.svg',
  '.odt'
]);
