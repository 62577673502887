angular.module('jb.constants', []);
angular.module('jb.controllers.components', []);
angular.module('jb.controllers.directives', []);
angular.module('jb.controllers', [
  'jb.controllers.components',
  'jb.controllers.directives'
]);
angular.module('jb.components', []);
angular.module('jb.directives', []);
angular.module('jb.factories', [
  'angularModalService',
  'rails'
]);
angular.module('jb.filters', []);
angular.module('jb.interceptors', []);
angular.module('jb.services', []);
angular.module('underscorejs', []);
angular.module('rxjs', []);
angular.module('templates', []);
angular.module('jb', [
  'jb.constants',
  'jb.controllers',
  'jb.components',
  'jb.directives',
  'jb.factories',
  'jb.filters',
  'jb.interceptors',
  'jb.services',
  'angucomplete-alt',
  'underscorejs',
  'rxjs',
  'templates',
  'ngRoute',
  'ngFileUpload',
  'ui.router',
  'ngResize'
]);
