angular.module('jb.controllers').controller('ThankYouController', ['$scope', 'jobTitle', 'JobBoardService',
  function($scope, jobTitle, JobBoardService) {
    this.$onInit = () => {
      $scope.jobTitle = jobTitle;
      $scope.trackingCodes = (JobBoardService.board || {}).tracking_codes;
    };

    $scope.message = () => {
      if (jobTitle) {
        return `Thank you for applying for ${jobTitle}.`;
      }
      return 'Thank you for submitting your resume.';
    };
  }
]);
