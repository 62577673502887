angular.module('jb.directives')
  .directive('jbCountryPicker', [function() {
    return {
      require: 'ngModel',
      controller: 'CountryPickerDirectiveController',
      scope: {
        ngModel: '=',
        ngStyle: '=',
        change: '=change',
        countryIds: '=',
        bb3Required: '='
      },
      templateUrl: 'directives/country_picker.html'
    };
  }]);
