angular.module('jb.controllers').controller('JobSearchController', ['$scope', 'pageNumber', 'searchParams', 'filter', 'searchResult', '$state', 'ViewStateService', '_', 'JobSearchFactory', 'JobAlertFactory', 'JobBoardService', '$rootScope', 'CountryIdConstants',
  function($scope, pageNumber, searchParams, filter, searchResult, $state, ViewStateService, _, JobSearchFactory, JobAlertFactory, JobBoardService, $rootScope, CountryIdConstants) {
    $scope.page = pageNumber;
    $scope.search = searchParams;
    $scope.filter = filter;
    $scope.pagination = searchResult.pagination;
    $scope.results = searchResult.results;
    $scope.quicklinkSearchId = $state.params.qlSearchId;
    $scope.networkQuicklinkSearchId = $state.params.nqlSearchId;
    $scope.form = {};
    $scope.alertCreated = false;
    $scope.success = false;

    $scope.showingQuicklinkResults = () => angular.isDefined($scope.quicklinkSearchId) || angular.isDefined($scope.networkQuicklinkSearchId);
    $scope.privacyComplianceEnabled = JobBoardService.getPrivacyComplianceEnabled();
    $scope.allowUserToSearchRemote = JobBoardService.allowRemoteSearching();

    JobBoardService.getBoard$().subscribe(() => {
      $scope.privacyComplianceEnabled = JobBoardService.getPrivacyComplianceEnabled();
      $scope.allowUserToSearchRemote = JobBoardService.allowRemoteSearching();
    });

    let searchIdDefined = () => angular.isDefined(searchResult.id) && searchResult.id !== null;

    if (searchIdDefined()) {
      $scope.search.keyword = searchResult.keyword;
      $scope.search.location = searchResult.location;
    }

    $scope.shouldShowCreateAlertForm = () => {
      if ($scope.alertCreated || !JobBoardService.hasVerifiedUrl() || searchIdDefined()) {
        return false;
      }

      let formIsDirty = $scope.form.search && $scope.form.search.$dirty;
      if (formIsDirty) {
        return false;
      }

      let isPresent = (value) => !!value;
      let specifiableParamNames = ['keyword', 'location', 'fullRemote'];
      let hasSpecifiedSearchParam = _.any(specifiableParamNames, paramName => isPresent(searchParams[paramName]));

      return hasSpecifiedSearchParam || $scope.showingQuicklinkResults();
    };

    $scope.alertHeader = () => {
      var uniqueString = ($scope.results.length > 0) ? 'like these' : 'matching this search';

      return `Get notified when jobs ${uniqueString} become available.`;
    };

    $scope.alertFormOpen = false;

    $scope.createAlert = () => {
      if ($scope.creating) {
        return;
      }

      $scope.creating = true;
      delete $scope.errorMessage;

      let definedParams = _.pick(searchParams, (v) => angular.isDefined(v));
      let finishCreating = () => {
        $scope.creating = false;
      };
      let handleSuccess = () => {
        $scope.alertFormOpen = false;
        $scope.alertCreated = true;
        $scope.success = true;
      };
      let handleFailure = (error) => {
        let errorMessage = 'There was an error processing your request.';

        if (error && error.data) {
          let alertError = error.data['job alert:'];
          if (alertError && alertError.length > 0) {
            errorMessage = alertError[0];
          }
        }

        $scope.errorMessage = errorMessage;
      };
      let createJobAlert = (search) => {
        var jobAlert = new JobAlertFactory({ emailAddress: $scope.emailAddress });
        if (angular.isDefined(search)) {
          jobAlert.jobSearchId = search.id;
        } else {
          jobAlert.quicklinkSearchId = $scope.quicklinkSearchId || $scope.networkQuicklinkSearchId;
          jobAlert.quicklinkSearchType = $scope.quicklinkSearchId ? 'standard' : 'network';
        }
        return jobAlert.create().then(handleSuccess, handleFailure);
      };
      if (!$scope.showingQuicklinkResults()) {
        var jobSearch = new JobSearchFactory(definedParams);
        jobSearch.create().then(createJobAlert, handleFailure).finally(finishCreating);
      } else {
        createJobAlert().finally(finishCreating);
      }
    };

    $scope.firstJobNumber = () => {
      return ($scope.pagination.currentPage - 1) * $scope.pagination.perPage + 1;
    };
    $scope.lastJobNumber = () => {
      let maxIndex = $scope.pagination.currentPage * $scope.pagination.perPage;
      return Math.min(maxIndex, $scope.pagination.total);
    };

    $scope.viewJob = (job) => {
      $state.go('root.job.detail', { id: job.id });
    };

    $scope.keywordPlaceholder = () => {
      if (ViewStateService.isMobileView()) {
        return 'What kind of opportunities?';
      }
      return 'What opportunities are you looking for?';
    };

    $scope.locationPlaceholder = () => {
      if (ViewStateService.isMobileView()) {
        return 'Where?';
      }
      return 'Where? (city, state or zip)';
    };

    $scope.srefToDetail = (job) => `root.job.detail({id:"${job.id}"})`;

    $scope.runSearch = function() {
      let stateParams = {
        qlSearchId: $scope.quicklinkSearchId,
        nqlSearchId: $scope.networkQuicklinkSearchId,
        page: $scope.page,
        params: undefined
      };

      stateParams.page = $scope.page;
      stateParams.filter = $scope.filter;

      let definedParams = _.pick($scope.search, (v) => angular.isDefined(v));

      if (_.some(definedParams)) {
        stateParams.params = angular.toJson(definedParams);
      }
      $state.go('root.search', stateParams, { reload: true });
    };

    $scope.pagesArray = () => {
      let numberOfOtherOptions = 6;
      let currentPage = $scope.pagination.currentPage;
      let min = _.max([1, currentPage - (numberOfOtherOptions / 2)]);
      let max = _.min([min + numberOfOtherOptions, $scope.pagination.totalPages]) + 1;

      let hasFewerThanPossible = (max - min - 1) < numberOfOtherOptions;
      if (hasFewerThanPossible) {
        min = _.max([1, max - numberOfOtherOptions - 1]);
      }

      return _.range(min, max);
    };

    $scope.goToPage = function(pageNumber) {
      let outOfBounds = pageNumber < 1 || pageNumber > $scope.pagination.totalPages;
      if (outOfBounds) {
        return;
      }
      $scope.page = pageNumber;
      $scope.runSearch();
    };

    $scope.runNewSearch = function() {
      $scope.page = 1;
      $scope.runSearch();
    };

    let handleStateChangeError = (event, toState, toParams, fromState, fromParams, error) => {
      let errorMessage = 'There was an error processing your request.';

      if (error && error.data) {
        let keywordError = error.data['keyword'];
        if (keywordError && keywordError.length > 0) {
          errorMessage = `Keyword ${keywordError[0]}`;
        }
      }

      $scope.searchErrorMessage = errorMessage;
    };

    $scope.countrySearchOnly = function() {
      let countryIdValues = [CountryIdConstants.UNITED_STATES, CountryIdConstants.CANADA];
      return !_.contains(countryIdValues, $scope.search.countryId);
    };

    $scope.allowCountrySearching = function() {
      return JobBoardService.allowCountrySearching();
    };

    $rootScope.$on('$stateChangeError', handleStateChangeError);

    $scope.$watch('search.countryId', function(newValue, oldValue) {
      if (newValue === oldValue || !$scope.countrySearchOnly()) {
        return;
      }

      $scope.search.location = undefined;
    });
  }
]);
