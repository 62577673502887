angular.module('jb.factories').factory('RemoteFileFactory', ['railsResourceFactory', 'PathToResourceRoute',
  function(railsResourceFactory, PathToResourceRoute) {
    let RemoteFileFactory = railsResourceFactory({
      url: PathToResourceRoute.convert(JBRoutes.jobBoardRemoteFilesPath),
      name: 'remote_file'
    });

    return RemoteFileFactory;
  }
]);
