angular.module('jb.components').component('jbPrivacyComplianceConfirmationEmailAddressModal', {
  bindings: {
    close: '<',
    requestType: '<'
  },
  templateUrl: 'privacy_compliance/confirmation_email_address.component.html',
  controller: ['PrivacyComplianceRequestFactory', 'RegexConstants',
    function(PrivacyComplianceRequestFactory, RegexConstants) {
      this.$onInit = () => {
        this.forms = {};
        this.emailRegex = RegexConstants.EmailAddress.source;
      };

      this.requestTitle = () => {
        switch (this.requestType) {
          case 'data':
            return 'Request My Info';
          case 'delete_me':
            return 'Forget My Info';
          case 'do_not_sell':
            return 'Do Not Sell My Info';
          default:
            return '';
        }
      };

      this.submit = () => {
        if (this.saving || this.forms.confirmForm.$invalid) {
          return false;
        }

        this.saving = true;

        delete this.errorMessage;

        let privacyComplianceRequest = new PrivacyComplianceRequestFactory();
        privacyComplianceRequest.emailAddress = this.emailAddress;
        privacyComplianceRequest.requestType = this.requestType;

        let handleFailure = () => {
          this.errorMessage = 'There was an error processing your request.';
        };

        privacyComplianceRequest.create().then(this.close, handleFailure).finally(() => this.saving = false);
      };

      this.cancel = () => {
        this.close();
      };
    }
  ]
});
