angular.module('jb.controllers').controller('JobDetailController', ['$scope', '$filter', '$sce', 'jobPosting', 'JobBoardService', '_', '$state',
  function($scope, $filter, $sce, jobPosting, JobBoardService, _, $state) {
    $scope.jobPosting = jobPosting;
    let job = jobPosting.job;
    $scope.job = job;
    job.descriptionHtml = $sce.trustAsHtml(job.description);

    $scope.displayJobId = JobBoardService.shouldDisplayJobId();
    $scope.displaySalary = JobBoardService.shouldDisplaySalary();
    $scope.displayJobType = JobBoardService.shouldDisplayJobType();

    JobBoardService.getBoard$().subscribe(() => {
      $scope.displayJobId = JobBoardService.shouldDisplayJobId();
      $scope.displaySalary = JobBoardService.shouldDisplaySalary();
      $scope.displayJobType = JobBoardService.shouldDisplayJobType();
    });

    $scope.srefToApply = (job) => `root.job.apply({jobId:"${job.id}"})`;

    $scope.educationSummary = (requirement) => {
      if (!requirement.degree) {
        return requirement.areaOfStudy;
      }
      let titleizedDegree = $filter('titleize')(requirement.degree);
      if (!requirement.areaOfStudy) {
        return titleizedDegree;
      }
      return `${titleizedDegree} in ${requirement.areaOfStudy}`;
    };

    $scope.requiredEducation = () => _.where(job.education, { required: true });
    $scope.optionalEducation = () => _.without(job.education, ...$scope.requiredEducation());
    $scope.hasSalary = () => job.minSalary || job.maxSalary;

    $scope.backToList = function() {
      if ($scope.fromState.name && $scope.fromParams) {
        $state.go($scope.fromState.name, $scope.fromParams);
        return;
      }

      $state.go('root.search');
    };

    var handleStateChangeSuccess = function(event, toState, toParams, fromState, fromParams) {
      $scope.fromState = fromState;
      $scope.fromParams = fromParams;
    };

    $scope.$on('$stateChangeSuccess', handleStateChangeSuccess);
  }
]);
