angular.module('jb.filters').filter('figureTimeFrame', function() {
  return function(datePosted) {
    var retval = {};
    var difference = (new Date() - Date.parse(datePosted));
    var seconds = Math.floor(difference / 1000);

    difference -= seconds * 1000;

    var minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    var hours = Math.floor(minutes / 60);
    minutes -= hours * 60;

    var days = Math.floor(hours / 24);
    hours -= days * 24;

    var weeks = Math.floor(days / 7);
    days -= weeks * 7;

    var months = Math.floor(weeks / 4);
    weeks -= months * 12;

    if (months > 12) {
      retval = {
        display: 'Over a year ago',
        class: ''
      };
    } else if (months > 1) {
      retval = {
        display: months + ' months ago',
        class: ''
      };
    } else if (months > 0) {
      retval = {
        display: '1 month ago',
        class: ''
      };
    } else if (weeks > 1) {
      retval = {
        display: weeks + ' weeks ago',
        class: ''
      };
    } else if (weeks > 0) {
      retval = {
        display: '1 week ago',
        class: ''
      };
    } else if (days > 1) {
      retval = {
        display: days + ' days ago',
        class: (days < 3 ? 'recent-job' : '')
      };
    } else if (days > 0) {
      retval = {
        display: '1 day ago',
        class: 'recent-job'
      };
    } else if (hours > 0) {
      retval = {
        display: hours + ' hours ago',
        class: 'recent-job'
      };
    } else {
      retval = {
        display: 'Just posted!',
        class: 'recent-job'
      };
    }
    return retval;
  };
});
