angular.module('jb.components').component('bb3TrackingCodes', {
  bindings: {
    trackingCodes: '<'
  },
  template: '<p></p>',
  controller: ['_', '$element', '$window',
    function(_, $element, $window) {
      this.$onInit = () => {
        let combinedHtml = _.reduce(this.trackingCodes, (htmlString, trackingCode) => htmlString += trackingCode.code, '');
        let element = $element[0];
        $window.postscribe(element, combinedHtml);
      };
    }
  ]
});
