angular.module('jb.factories').factory('PrivacyComplianceRequestFactory', ['railsResourceFactory', 'PathToResourceRoute',
  function(railsResourceFactory, PathToResourceRoute) {
    let PrivacyComplianceRequestFactory = railsResourceFactory({
      url: PathToResourceRoute.convert(JBRoutes.jobBoardPrivacyComplianceRequestsPath),
      name: 'privacyComplianceRequest'
    });

    PrivacyComplianceRequestFactory.prototype.confirm = function() {
      let path = JBRoutes.jobBoardConfirmPrivacyComplianceRequestPath({ id: this.id });
      return PrivacyComplianceRequestFactory.$get(path);
    };

    return PrivacyComplianceRequestFactory;
  }
]);
