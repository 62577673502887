angular.module('jb.factories').factory('SingleInstanceRequestFactory', ['$q', function($q) {
  class SingleInstanceRequest {
    constructor(callback) {
      this.callback = callback;
    }

    getOrCreate() {
      if (!this.pendingRequest) {
        this.pendingRequest = this.callback().then(
          (response) => {
            this.pendingRequest = null;
            return response;
          },
          (response) => {
            this.pendingRequest = null;
            return $q.reject(response);
          }
        );
      }

      return this.pendingRequest;
    }
  }

  return SingleInstanceRequest;
}]);
