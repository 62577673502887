/* eslint angular/window-service: 0 */
(() => {
  window.JobBoardConfig = {};

  let jobBoard = document.querySelector('job-board') || document.getElementById('jb--job-board');
  if (!jobBoard) {
    return;
  }

  let configureJobBoard = () => {
    let match = window.location.search.match(/board=([0-9a-zA-Z=]*)/);
    window.JobBoardConfig.apiKey = match ? match[1] : jobBoard.getAttribute('api-key');
    window.JobBoardConfig.domain = 'http://jbweb:8082';
    window.JobBoardConfig.element = jobBoard;
  };

  let loadAngularApp = () => {
    let htmlString = '<ui-view class="jb--wrapper" ng-class="{\'jb--mobile\':isMobileView()}"></ui-view>';
    let element = angular.element(htmlString);
    jobBoard.appendChild(element[0]);

    jobBoard.setAttribute('ng-app', 'jb');
  };

  configureJobBoard();
  loadAngularApp();
})();
