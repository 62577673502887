angular.module('jb.controllers').controller('ApplyController', ['$scope', '$state', 'application', 'PhoneNumberTypesConstant', 'RegexConstants', 'JobBoardService',
  function($scope, $state, application, PhoneNumberTypesConstant, RegexConstants, JobBoardService) {
    $scope.application = application;
    $scope.job = application.job;
    $scope.uploadingResume = false;
    $scope.consentToText = '';

    $scope.phoneNumberTypes = PhoneNumberTypesConstant;
    $scope.emailRegex = RegexConstants.EmailAddress.source;
    $scope.ccpaEnabled = JobBoardService.getCcpaEnabled();
    $scope.gdprEnabled = JobBoardService.getGdprEnabled();
    $scope.privacyComplianceUrl = JobBoardService.getPrivacyComplianceUrl();
    $scope.agencyName = JobBoardService.getAgencyName();
    $scope.ctaPrivacyPolicyUrl = JobBoardService.getCtaPrivacyPolicyUrl();
    $scope.termsAndConditions = JobBoardService.getTermsAndConditions();

    JobBoardService.getBoard$().subscribe(() => {
      $scope.ccpaEnabled = JobBoardService.getCcpaEnabled();
      $scope.gdprEnabled = JobBoardService.getGdprEnabled();
      $scope.privacyComplianceUrl = JobBoardService.getPrivacyComplianceUrl();
      $scope.agencyName = JobBoardService.getAgencyName();
      $scope.ctaPrivacyPolicyUrl = JobBoardService.getCtaPrivacyPolicyUrl();
      $scope.termsAndConditions = JobBoardService.getTermsAndConditions();
    });

    $scope.changePhoneNumber = () => {
      if (!$scope.application.phoneNumber) {
        $scope.consentToText = '';
      }
    };

    $scope.submitApplication = () => {
      if ($scope.saving || $scope.applyForm.$invalid) {
        return false;
      }

      delete $scope.errorMessage;

      if ($scope.job && !$scope.job.ownedByAgency && $scope.ccpaEnabled && $scope.application.doNotSell) {
        $scope.errorMessage = 'We are helping an affiliated firm fill this job. You cannot apply to this job with "Do Not Sell" enabled because we need to share your profile with them.';
        return false;
      }

      if ($scope.gdprEnabled && !$scope.application.gdprConsent) {
        $scope.errorMessage = 'You must consent to the processing of your personal data to proceed.';
        return false;
      }

      if (!!$scope.application.phoneNumber && !$scope.consentToText) {
        $scope.errorMessage = 'Please choose a consent-to-text answer or remove phone number.';
        return false;
      }

      if ($scope.application.gdprConsent) {
        $scope.application.gdprConsentedAt = new Date();
      }

      $scope.application.doNotText = $scope.consentToText === 'no';

      $scope.saving = true;
      let success = () => {
        $scope.saving = false;
        $state.go('^.thanks');
      };
      let failure = (result) => {
        $scope.saving = false;
        $scope.errorMessage = result.data.error || 'There was an error processing your request.';
      };
      application.save().then(success, failure);
    };
  }
]);
