angular.module('jb.factories').factory('JobAlertFactory', ['railsResourceFactory', 'PathToResourceRoute',
  function(railsResourceFactory, PathToResourceRoute) {
    let JobAlertFactory = railsResourceFactory({
      url: PathToResourceRoute.convert(JBRoutes.jobBoardJobAlertsPath),
      name: 'jobAlert'
    });

    JobAlertFactory.prototype.cancel = function(cancelAll = false) {
      let path = JBRoutes.jobBoardCancelJobAlertPath({ id: this.id });
      return JobAlertFactory.$put(path, { all: cancelAll });
    };

    JobAlertFactory.prototype.renew = function() {
      let path = JBRoutes.jobBoardRenewJobAlertPath({ id: this.id });
      return JobAlertFactory.$get(path);
    };

    return JobAlertFactory;
  }
]);
