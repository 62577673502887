angular.module('jb.controllers').controller('JobAlertRenewController', ['$scope', 'jobAlert',
  function($scope, jobAlert) {
    $scope.jobAlert = jobAlert;
    $scope.renewed = false;
    $scope.renewing = true;

    let handleSuccess = (data) => {
      $scope.updatedExpiresAt = data.updatedExpiresAt;
      $scope.renewed = true;
    };
    let handleFailure = () => {
      $scope.errorMessage = 'There was an error processing your request.';
    };
    let finishRenewing = () => {
      $scope.renewing = false;
    };

    jobAlert.renew().then(handleSuccess, handleFailure).finally(finishRenewing);
  }
]);
