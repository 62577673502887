angular.module('jb.controllers.components').controller('ResumePickerComponentController', ['Upload', 'PermittedResumeExtensionsConstant',
  function(Upload, PermittedResumeExtensionsConstant) {
    this.uploadedResult = null;
    this.fileTypes = PermittedResumeExtensionsConstant;

    // this function must be kept the same as the function of the same name found in:
    // bb_web/src/app/common/user-file/editable.ts
    this.isEditable = (userFile) => userFile.filename.endsWith('.txt');

    this.uploadResume = (file) => {
      if (this.uploadingResume || !file) {
        return;
      }

      let success = (response) => {
        this.application.userFileId = response.data.user_file_id;
        this.filename = file.name;
        this.uploadingResume = false;
      };
      let failure = () => {
        this.uploadedResult = null;
        this.uploadingResume = false;
      };

      let params = {
        url: JBRoutes.jobBoardUploadedFilesPath(),
        data: {
          file: file,
          job_id: this.application.jobId
        },
        // ngFileUpload does a hack with XMLHttpRequest to track progress.
        // Disable this with disableProgress: true to prevent issues w/ other
        // scripts that may also manipulate XMLHttpRequest
        // Hack: https://github.com/danialfarid/ng-file-upload/blob/master/src/upload.js#L9
        // Issue: https://catsone.atlassian.net/browse/TE-119
        // Slack Thread: https://topechelon.slack.com/archives/CJNEE83FW/p1614273017025400
        disableProgress: true
      };

      this.uploadingResume = true;
      Upload.upload(params).then(success, failure);
    };
  }
]);
