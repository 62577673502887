angular.module('jb.directives').directive('jbOnlyDigits', function() {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.unshift(function(inputValue) {
        var digits = inputValue.split('').filter(function(s) {
          return (!isNaN(s) && s !== ' ' || s === '.');
        }).join('');
        ngModel.$viewValue = digits;
        ngModel.$render();
        return digits;
      });
    }
  };
});
