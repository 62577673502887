angular.module('jb.services').service('ViewStateService', ['$document', 'JobBoardConfig',
  function($document, JobBoardConfig) {
    const MOBILE_VIEW_MAX = 700;

    this.isMobileView = () => {
      let isMobileView = JobBoardConfig.element.getBoundingClientRect().width < MOBILE_VIEW_MAX;
      return isMobileView;
    };
  }
]);
